import React, { lazy, Suspense } from 'react';

const ReactForVisitors = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'ReactForVisitorsLazy' */ './ReactForVisitors'));

function ReactForVisitorsLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading ReactForVisitors ...</div>}>
                <ReactForVisitors {...props} />
            </Suspense>
        </div>
    );
}

export default ReactForVisitorsLazy;